@if (isAdmin()) {
  @defer (when isAdmin()) {
    <div class="inline-flex min-h-screen w-screen bg-[#f1f5f9]">
      @if (isValidSession) {
        <app-side-bar ></app-side-bar>
      }
      <div class="flex-grow h-screen">
        @if (isValidSession) {
          <admin-navbar class="fixed top-0 w-full z-[9999]"></admin-navbar>
        }
        <div class="admin-body">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  }
} @else {
  <app-navbar>
    <router-outlet></router-outlet>
  </app-navbar>
}

<div class="flex flex-col p-10 min-w-[90vw] sm:min-w-[400px] items-center justify-center">
  <div class="inline-flex justify-end pb-8 w-full">
    <svg
      (click)="dialogRef.close()"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.8"
      stroke="currentColor"
      class="size-12 text-gunmetal"
    >
      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
    </svg>
  </div>

  @if (showServices()) {
    <div class="flex flex-col gap-8">
      @for (group of data.services; track $index) {
        <div class="flex flex-col gap-2">
          <h3 class="text-black text-20 font-bold marcellus capitalize">{{ group.title }}</h3>
          <div class="flex flex-col gap-3 pl-6">
            @for (value of group.services; track $index) {
              <h3 (click)="openServices(group.title, $index)" class="text-gunmetal font-normal text-16">
                {{ value }}
              </h3>
            }
          </div>
        </div>
      }
    </div>
  } @else {
    <div class="flex flex-col gap-10 items-center justify-center">
      @for (item of data.items; track $index) {
        <h3 (click)="openLink(item)" class="text-gunmetal font-semibold text-20">{{ item.title }}</h3>
      }
    </div>

    <app-button class="mx-auto mt-16" (click)="openBooking()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        class="w-8 h-8 mr-3 text-gunmetal"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
        />
      </svg>
      APPOINTMENT
    </app-button>
  }
</div>

import { AfterViewInit, ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';
import { CloudinaryService } from '../../../core/services/cloudinary.service';
import { DialogService } from '../../../core/services/dialog.service';
import { WidgetService } from '../../../core/services/widget.service';
import { WidgetFetcher } from '../../../core/types/widgets/fetcher';
import { BeforeAfterComponent } from '../../../shared/components/before-after/before-after.component';
import { ContactUsComponent } from '../../../shared/components/contact-us/contact-us.component';
import { ReservationComponent } from '../../../shared/components/reservation/reservation.component';
import { ReviewsComponent } from '../../../shared/components/reviews/reviews.component';
import { WidgetEditorComponent } from '../../admin/pages/widget-editor/widget-editor.component';
import { AboutClinicComponent } from '../components/about-clinic/about-clinic.component';
import { AboutDirectorComponent } from '../components/about-director/about-director.component';
import { HomeLandingWidgetComponent } from '../components/home-landing-widget/home-landing-widget.component';
import { HomeServiceCardsComponent } from '../home-service-cards/home-service-cards.component';

@Component({
  selector: 'app-home-page',
  standalone: true,
  imports: [
    BeforeAfterComponent,
    HomeServiceCardsComponent,
    HomeLandingWidgetComponent,
    AboutClinicComponent,
    ReservationComponent,
    ContactUsComponent,
    AboutDirectorComponent,
    ReviewsComponent,
    WidgetEditorComponent,
  ],
  templateUrl: './home-page.component.html',
  styleUrl: './home-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomePageComponent extends WidgetFetcher implements OnInit, AfterViewInit {
  override pageKey = 'home_page';

  constructor(
    public cloudinary: CloudinaryService,
    public _dialog: MatDialog,
    public _dialogService: DialogService,
    public override widgetService: WidgetService,
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.fetchWidgetData([this.pageKey]);
  }

  ngAfterViewInit(): void {
    if (environment.production) {
      let slugs: any = [];
      for (const key of Object.keys(this.widgetService.services)) {
        slugs = [...this.widgetService.services[key].map((e: any) => e.key), ...slugs];
      }
      this.widgetService.getData(slugs);
    }
  }

  opneBookings() {
    this._dialogService.openBookings();
  }
}

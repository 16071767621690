import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CloudinaryService } from '../../services/cloudinary.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  payments = ['skinLogic/misc/ae_nyuyoa.png', 'skinLogic/misc/visa_sxseqg.png', 'skinLogic/misc/master_aadw1g.png'];
  socialMedia = [
    {
      title: 'Instagram',
      link: '',
      image: 'skinLogic/misc/insta_eit6cv.png',
    },
    {
      title: 'Facebook',
      link: '',
      image: 'skinLogic/misc/fb_azef54.png',
    },
  ];

  constructor(public cloudinary: CloudinaryService) {}

  get currentYear() {
    return new Date().getFullYear();
  }
}

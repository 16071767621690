import { Routes } from '@angular/router';
import { authGuardGuard } from './core/providers/auth-guard.guard';
import { HomePageComponent } from './features/home/home-page/home-page.component';

export const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
  },
  {
    path: 'blogs',
    loadComponent: () => import('./features/blogs/blog-list/blog-list.component').then((m) => m.BlogListComponent),
    data: { 'reusable': true },
  },
  {
    path: 'blogs/details/:slug',
    loadComponent: () =>
      import('./features/blogs/blog-details/blog-details.component').then((m) => m.BlogDetailsComponent),
  },
  {
    path: 'services',
    loadComponent: () =>
      import('./features/services/services-page/services-page.component').then((m) => m.ServicesPageComponent),
  },
  {
    path: 'admin/login',
    loadComponent: () => import('./features/admin/auth/login/login.component').then((m) => m.LoginComponent),
  },
  // {
  //   path: 'admin/pages',
  //   component: PagesListComponent,
  //   canActivate: [authGuardGuard],
  // },
  {
    path: 'admin/bookings',
    loadComponent: () =>
      import('./features/admin/bookings/bookings-list/bookings-list.component').then((m) => m.BookingsListComponent),
    canActivate: [authGuardGuard],
  },
  {
    path: 'cynoglow',
    loadComponent: () => import('./features/misc/cynoglow/cynoglow.component').then((m) => m.CynoglowComponent),
  },
  {
    path: 'potenza',
    loadComponent: () => import('./features/misc/potenza/potenza.component').then((m) => m.PotenzaComponent),
  },
  {
    path: 'privacy-policy',
    loadComponent: () =>
      import('./features/misc/privacy-policy/privacy-policy.component').then((m) => m.PrivacyPolicyComponent),
  },
  {
    path: 'about-us',
    loadComponent: () => import('./features/misc/about-us/about-us.component').then((m) => m.AboutUsComponent),
  },
  {
    path: 'dev-utils',
    loadComponent: () =>
      import('./features/admin/auth/controls/dev-utils/dev-utils.component').then((m) => m.DevUtilsComponent),
  },
  {
    path: 'dev-settings',
    loadComponent: () =>
      import('./features/admin/auth/controls/dev-settings/dev-settings.component').then((m) => m.DevSettingsComponent),
  },
  {
    path: '**',
    component: HomePageComponent,
  },
];

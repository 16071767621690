import { ChangeDetectionStrategy, Component, OnInit, signal } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouteReuseStrategy, RouterOutlet } from '@angular/router';
import { CloudinaryModule } from '@cloudinary/ng';
import { NavbarComponent } from './core/components/navbar/navbar.component';
import { CustomReUseStratergy } from './core/providers/route-reuse';
import { UserService } from './core/services/user.service';
import { AdminNavbarComponent } from './features/admin/components/admin-navbar/admin-navbar.component';
import { SideBarComponent } from './features/admin/components/side-bar/side-bar.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, SideBarComponent, NavbarComponent, AdminNavbarComponent, CloudinaryModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  isAdmin = signal(false);

  constructor(
    private router: Router,
    private userService: UserService,
    private routeReuse: RouteReuseStrategy,
  ) {}

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationStart) {
        this.isAdmin.set(evt.url.startsWith(`/admin`));
        // Track pop
        const navigation = this.router.getCurrentNavigation();
        (<CustomReUseStratergy>this.routeReuse).didPop = navigation?.trigger === 'popstate';
      }
    });
  }

  get isValidSession() {
    return this.userService.isValidSession;
  }
}

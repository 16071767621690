<div class="flex flex-col bg-white">
  <home-landing-widget class="mb-24"></home-landing-widget>
  <service-cards [flip]="true"></service-cards>
  @defer {
    <widget-editor [data]="widgetData" [slug]="pageKey" [keys]="['about_clinic']" class="sl-box mt-28 lg:mt-40">
      <about-clinic [isWidgetFetched]="isWidgetFetched" [data]="getValue('about_clinic')"></about-clinic>
    </widget-editor>

    <widget-editor [data]="widgetData" [slug]="pageKey" [keys]="['about_director']" class="sl-boxx">
      <app-about-director [isWidgetFetched]="isWidgetFetched" [data]="getValue('about_director')"></app-about-director>
    </widget-editor>

    <app-reviews></app-reviews>
    <div class="flex flex-col-reverse lg:flex-row items-center gap-28 sl-box py-36 bg-white">
      <app-contact-us class="flex-1 w-full"></app-contact-us>
      <app-reservation class="flex-1 w-full"></app-reservation>
    </div>
  }
</div>

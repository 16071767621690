<div class="flex flex-col relative">
  <!-- <div class="flex flex-col bg-gradient-to-b lg:bg-gradient-to-r from-[#DF88BB] via-[#DCACA7] to-[#E4DBD3]">
    <div class="flex flex-col lg:flex-row items-center pt-10 px-10">
      <div class="flex-[5] flex flex-col h-full justify-center pb-20">
        <h3 class="marcellus font-semibold text-[40px] text-white">10% off promo of ANY procedure as a new client!</h3>
        <h4 class="text-20 font-extralight text-white pt-4">
          Schedule your session today and be a part of Arizona's exclusive glow revolution.
        </h4>

        <div class="flex flex-col md:flex-row gap-16 pt-16 justify-center">
          <app-button classNames="!py-6 !px-10 !w-full !min-w-[197px]">LEARN MORE</app-button>
          <app-button (click)="opneBookings()" classNames="!py-6 !px-10 !w-full !min-w-[197px]"
            >MAKE A RESERVATION</app-button
          >
        </div>
      </div>
      <div class="flex-[5] w-full h-full">
        <img
          [src]="cloudinary.getUrl({ id: 'skinLogic/LWXoTtqamUrK_fyeopv.png', width: 1000 })"
          class="w-11/12 md:w-1/2 lg:w-3/4 mx-auto h-auto"
        />
      </div>
    </div>
  </div> -->
  <div class="keen-slider lg:!w-full mx-auto group" #sliderRef>
    @for (item of data; track $index) {
      <div class="keen-slider__slide flex flex-col relative bg-white">
        <div
          class="flex flex-col sl-box bg-gradient-to-b lg:bg-gradient-to-r from-[#DF88BB] via-[#DCACA7] to-[#E4DBD3] h-full"
        >
          <div class="flex flex-col lg:flex-row items-center justify-between pt-10 px-10 relative h-full lg:h-[500px]">
            <div class="lg:w-[60%] z-50 flex flex-col h-full justify-center pb-10">
              <h3
                [ngClass]="intersectData()[$index] ? 'block scale-up-center' : 'hidden'"
                class="marcellus font-semibold text-[36px] text-white ts"
              >
                {{ item.title }}
              </h3>
              <h4
                [ngClass]="intersectData()[$index] ? 'block scale-up-center' : 'hidden'"
                class="text-20 font-normal text-white pt-4"
              >
                {{ item.info }}
              </h4>

              <div class="flex flex-col md:flex-row gap-16 pt-16 justify-center items-stretch lg:justify-start">
                @if (item.showLearMore) {
                  <app-button (click)="openLink(item)" classNames="!py-6 !px-10 !w-full md:!min-w-[280px]"
                    >LEARN MORE</app-button
                  >
                }
                @if (item.showReservation) {
                  <app-button classNames="!py-6 !px-10 !w-full md:!min-w-[280px]" (click)="opneBookings()"
                    >Book Your Free Consultation!
                  </app-button>
                }
              </div>
            </div>
            <!-- <div class="flex-[4] flex w-full h-full lg:h-[500px] lg:overflow-visible">
              <img
                [src]="cloudinary.getUrl({ id: item.image, width: 1000 })"
                class="max-h-[300px] lg:max-h-none mt-auto lg:mt-0 mx-auto h-full lg:h-[500px] lg:max-w-[80%] lg:object-cover lg:object-center lg:overflow-visible"
              />
            </div> -->
            @defer (on viewport; on idle) {
              <img
                fetchpriority="high"
                [alt]="item.title"
                [src]="cloudinary.getUrl({ id: item.image, height: 500 })"
                class="mt-auto max-h-[300px] lg:max-h-none h-full lg:h-[90%] w-auto object-contain lg:absolute bottom-0 lg:right-4"
              />
            } @placeholder {
              <h3></h3>
            }
          </div>
        </div>
      </div>
    }
    <!-- Slide button starts -->
    <div
      class="hidden lg:group-hover:inline-flex sl-box w-full items-center absolute top-1/2 -translate-y-1/2 justify-between"
    >
      <div class="p-3 border rounded-lg shadow-lg bg-white bg-opacity-75 cursor-pointer" (click)="this.slider?.prev()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-10 text-black"
        >
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
        </svg>
      </div>

      <div class="p-3 border rounded-lg shadow-lg bg-white bg-opacity-75 cursor-pointer" (click)="this.slider?.next()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-10 text-black"
        >
          <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
        </svg>
      </div>
    </div>
    <!-- Slide button ends -->
  </div>
  <!-- Slide indicator starts -->
  <div class="inline-flex sl-box items-center justify-center gap-8 absolute top-[14px] left-0 right-0">
    @for (item of data; track $index) {
      <div
        [ngClass]="$index == activeIndex() ? 'bg-opacity-100 w-[20px]' : 'bg-opacity-40 w-[12px]'"
        class="h-[4px] transition-all rounded-full bg-white"
      ></div>
    }
  </div>
  <!-- Slide indicator ends -->

  <div
    class="flex flex-col md:flex-row flex-wrap count-up items-center bg-[#F0EDE4] gap-16 lg:gap-0 w-full lg:w-3/4 py-6 mx-auto lg:translate-y-[-50%]"
  >
    <div class="inline-flex justify-between w-full md:flex-[2]">
      <div class="flex-1 flex flex-col items-center justify-center gap-1">
        <h3 class="text-38 marcellus"><span [countUp]="20" [duration]="3000"></span>+</h3>
        <h4 class="text-gunmetal">years experience</h4>
      </div>
      <div class="flex-1 flex flex-col items-center justify-center gap-3">
        <h3 class="text-38 marcellus"><span [countUp]="1000" [duration]="3000"></span>+</h3>
        <h4 class="text-gunmetal">happy clients</h4>
      </div>
    </div>
    <div class="flex-[1] flex flex-col items-center justify-center gap-3">
      <h3 class="text-38 marcellus"><span [countUp]="3000" [duration]="3000"></span>+</h3>
      <h4 class="text-gunmetal">procedures completed</h4>
    </div>
  </div>
</div>

import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

export class CustomReUseStratergy implements RouteReuseStrategy {
  private readonly storedRoutes = new Map<string, DetachedRouteHandle>();
  public didPop = false;

  canReUse(route: ActivatedRouteSnapshot) {
    return route.routeConfig?.data?.['reusable'];
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return this.canReUse(route);
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    if (this.canReUse(route) && route.routeConfig?.path) this.storedRoutes.set(route.routeConfig.path, handle);
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return this.didPop && this.storedRoutes.has(route.routeConfig?.path ?? '');
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return this.storedRoutes.get(route.routeConfig?.path ?? '') ?? null;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }
}

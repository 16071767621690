import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { REST_API } from '../constants/api.constants';
import { UserService } from '../services/user.service';
import { environment } from '../../../environments/environment';
@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
  private _refreshInProgress = false;
  private _accessTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(
    private _userService: UserService,
    private _http: HttpClient,
  ) {}
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((evt: HttpEvent<any>) => this._modifyResponse(evt)),
      catchError((err: HttpErrorResponse) => {
        if (environment.production == false) console.log('API ERROR - ' + err?.url, err);
        // Access token expired/invalid
        if (err.status == 401) {
          if (err.url == REST_API.tokenRefresh) this._userService.logout();
          else return this._refreshToken(req, next);
        }
        // Refresh token expired/invalid
        else if (err.status == 403) {
          this._userService.logout();
        }

        return throwError(err.error);
      }),
    );
  }

  // Fetch new access token using refresh token
  private _refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this._refreshInProgress) {
      this._refreshInProgress = true;
      this._accessTokenSubject.next(null);
      return this._http.post(REST_API.tokenRefresh, { 'refresh': this._userService.userData?.refreshToken }).pipe(
        switchMap((res: any) => {
          this._refreshInProgress = false;
          const token = res['access'];
          this._userService.updateUserData({ accessToken: token });
          this._accessTokenSubject.next(token);
          return this._addAuthorizationHeader(next, request, token);
        }),
      );
    } else {
      return this._accessTokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((token) => {
          return this._addAuthorizationHeader(next, request, token);
        }),
      );
    }
  }

  // Hit the API request again with the updated access token
  private _addAuthorizationHeader(
    next: HttpHandler,
    request: HttpRequest<any>,
    token: string | null,
  ): Observable<HttpEvent<any>> {
    return next
      .handle(
        request.clone({
          setHeaders: { Authorization: `Bearer ${token}` },
        }),
      )
      .pipe(tap((evt: HttpEvent<any>) => this._modifyResponse(evt)));
  }

  private _modifyResponse(evt: HttpEvent<any>): HttpEvent<any> {
    if (evt instanceof HttpResponse) {
      if (environment.production == false) {
        console.group('API RESPONSE');
        console.info('URL - ', evt.url);
        console.info('PAYLOAD - ', evt.body);
        console.groupEnd();
      }
      if (evt.body && evt.body.code && evt.body.code !== 200) {
        throw new HttpErrorResponse({
          error: evt.body,
          headers: evt.headers,
          status: evt.body.code,
          statusText: 'NOT OK',
          url: evt.url ?? '',
        });
      }
      if (evt.body?.status) evt.body['isSuccess'] = evt.body.status.toLocaleLowerCase() == 'success';
    }
    return evt;
  }
}

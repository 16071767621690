<div class="flex flex-col">
  <div class="flex flex-col bg-[#555150] sl-box py-20">
    <div class="inline-flex pb-10">
      <h3 #companyName class="text-white marcellus text-32">Skin Logic Aesthetics</h3>
    </div>
    <div class="flex flex-col lg:flex-row gap-20">
      <a href="https://maps.app.goo.gl/fj7g3utzbjWVHaoQA" target="_blank" class="flex-1">
        <div class="flex flex-col">
          <h3 class="text-gray-100 font-semibold text-18 pb-4">Address</h3>
          <h4 class="text-gray-100 font-medium text-16 pb-2">
            Fountain Medical Plaza. 5620 W. Thunderbird Ave. D-2. Glendale, AZ 85306
          </h4>
          @defer (on viewport(companyName); on  timer(10s)) {
            <img
              [src]="cloudinary.getUrl({ id: 'misc/map_ba8xpu.png', width: 100 })"
              alt="location"
              class="h-[50px] w-[50px] rounded-lg object-cover object-center"
            />
          } @placeholder {
            <div class="shimmer rounded-lg h-[50px] aspect-1"></div>
          }
        </div>
      </a>

      <div class="flex flex-col flex-1">
        <h3 class="text-gray-100 font-semibold text-18 pb-5">We accept</h3>
        @defer (on viewport(companyName); on  timer(10s)) {
          <div class="inline-flex flex-wrap gap-10">
            @for (url of payments; track $index) {
              <img
                fetchpriority="low"
                class="h-[60px] aspect-[95/60]"
                [src]="cloudinary.getUrl({ id: url, height: 120 })"
                alt="Bank Logo"
              />
            }
          </div>
        } @placeholder {
          <div class="inline-flex flex-wrap gap-10">
            @for (url of payments; track $index) {
              <div class="shimmer rounded-lg h-[60px] aspect-[95/60]"></div>
            }
          </div>
        }
      </div>

      <div class="flex flex-col flex-1">
        <h3 class="text-gray-100 font-semibold text-18 pb-5">Follow us</h3>

        @defer (on viewport(companyName); on  timer(10s)) {
          <div class="inline-flex flex-wrap gap-10">
            @for (item of socialMedia; track $index) {
              <img
                fetchpriority="low"
                class="h-[60px] aspect-1"
                [src]="cloudinary.getUrl({ id: item.image, height: 90 })"
                [alt]="item.title"
              />
            }
          </div>
        } @placeholder {
          <div class="inline-flex flex-wrap gap-10">
            @for (item of socialMedia; track $index) {
              <div class="shimmer rounded-lg h-[60px] aspect-1"></div>
            }
          </div>
        }
      </div>
    </div>

    <div class="inline-flex gap-20 pt-16">
      <div class="flex flex-col flex-1">
        <h3 class="text-gray-100 font-semibold text-18 pb-4">Hours of operation</h3>
        <h4 class="text-gray-100 font-medium text-16">Monday to Friday</h4>
        <h4 class="text-gray-100 font-medium text-16">9:00am to 5:00pm</h4>
      </div>

      <div class="flex flex-col flex-1">
        <h4 routerLink="/privacy-policy" class="text-gray-100 font-medium text-16 cursor-pointer">Privacy Policy</h4>
        <!-- <h4 class="text-greyish font-medium text-16">Contact Us</h4> -->
      </div>

      <div class="flex flex-col lg:flex-1"></div>
    </div>
  </div>
  <div class="inline-flex items-center justify-center bg bg-darkBrown py-8">
    <h3 class="text-white text-16">Copyright {{ currentYear }} Skin Logic Aesthetics</h3>
  </div>
</div>

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { REST_API } from '../constants/api.constants';
import { UserService } from '../services/user.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  bearerExcludedUrls: Array<string> = [
    REST_API.login,
    REST_API.tokenRefresh,
    REST_API.booking,
    REST_API.pagesDetails,
    REST_API.blogData,
    REST_API.blogTagsList,
    REST_API.blogDetails,
  ];

  constructor(private _userService: UserService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Skip blackListed urls for bearer token
    if (this.isUrlExcluded(req.url)) return next.handle(req);

    const clonedReq = req.clone({
      setHeaders: { Authorization: `Bearer ${this._userService.accessToken}` },
    });

    return next.handle(clonedReq);
  }

  private isUrlExcluded(reqUrl: string): boolean {
    return this.bearerExcludedUrls.findIndex((url) => reqUrl.startsWith(url)) > -1;
  }
}

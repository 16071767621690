import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { AdminControlComponent } from '../../../features/admin/auth/controls/admin-control/admin-control.component';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { DialogService } from '../../services/dialog.service';
import { UserService } from '../../services/user.service';
import { NavigationItem } from '../../types/navigation-item';
import { FooterComponent } from '../footer/footer.component';
import { MobileMenuComponent } from '../mobile-menu/mobile-menu.component';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RouterLink, ButtonComponent, MatMenuModule, FooterComponent],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent {
  constructor(
    public router: Router,
    public userService: UserService,
    public _dialogService: DialogService,
    private dialog: MatDialog,
  ) {}

  menuItems: NavigationItem[] = [
    {
      id: 1,
      title: 'Home',
      path: '',
    },
    {
      id: 2,
      title: 'Services',
      dropDownKey: 'services',
    },
    {
      id: 3,
      title: 'About Us',
      path: 'about-us',
    },
    {
      id: 4,
      title: 'Blogs',
      path: 'blogs',
    },
  ];

  services = [
    {
      title: 'restorative',
      services: [
        'Microneedling RF',
        'Focus Array or Elite+',
        'Coolsculpting - Fat Reduction',
        'Fibroblast - Plasma Pen',
        'FUE Hair Transplants',
      ],
    },
    {
      title: 'corrective',
      services: [
        'Tattoo Removal - PicoSure Laser',
        'Superficial Vein Treatments',
        'Laser Hair Reduction',
        'Skin Tag Removal',
      ],
    },
    {
      title: 'nourishing',
      services: ['Dermaplaning - Exfoliating', 'Hydrodermabrasion', 'Hydrating Facials', 'Pelleve/Tempsure'],
    },
  ];

  openServices(key: string, index: number) {
    this.router.navigate(['services'], { replaceUrl: false, queryParams: { type: key, index: index } });
  }

  opneBookings() {
    this._dialogService.openBookings();
  }

  mobileLink(item: NavigationItem, drawer: any) {
    this.router.navigate([item.path], { replaceUrl: false });
    drawer.toggle();
  }

  openMobileMenu() {
    this.dialog.open(MobileMenuComponent, {
      data: { items: this.menuItems, services: this.services },
      autoFocus: false,
      maxWidth: '95vw',
      maxHeight: '95vh',
    });
  }

  showAdminControls() {
    this.dialog.open(AdminControlComponent, {
      autoFocus: false,
      minWidth: '500px',
      minHeight: '400px',
      maxWidth: '95vw',
      maxHeight: '95vh',
    });
  }
}

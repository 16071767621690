import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { StorageKeys } from '../../../../../core/constants/storage.constants';
import { StorageService } from '../../../../../core/services/storage.service';
import { UserService } from '../../../../../core/services/user.service';
import { ButtonComponent } from '../../../../../shared/components/button/button.component';
import { BlogSettingsComponent } from '../blog-settings/blog-settings.component';

@Component({
  selector: 'app-admin-control',
  standalone: true,
  imports: [ButtonComponent, MatSlideToggleModule, CommonModule],
  templateUrl: './admin-control.component.html',
  styleUrl: './admin-control.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminControlComponent {
  constructor(
    public dialogRef: MatDialogRef<AdminControlComponent>,
    public userService: UserService,
    private dialog: MatDialog,
    private _storageService: StorageService,
  ) {}

  toggleEdit(value: boolean) {
    this._storageService.setValue(StorageKeys.editMode, value);
    this.userService.showEdit.set(value);
  }

  openBlogSettings() {
    this.dialog.open(BlogSettingsComponent, {
      autoFocus: false,
      minWidth: '50vw',
      maxWidth: '95vw',
      minHeight: '50vh',
      maxHeight: '95vh',
    });
  }
}

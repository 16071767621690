import { ChangeDetectionStrategy, Component, Inject, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router, RouterLink } from '@angular/router';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { DialogService } from '../../services/dialog.service';
import { NavigationItem } from '../../types/navigation-item';

@Component({
  selector: 'app-mobile-menu',
  standalone: true,
  imports: [ButtonComponent, RouterLink],
  templateUrl: './mobile-menu.component.html',
  styleUrl: './mobile-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileMenuComponent {
  showServices = signal(false);

  constructor(
    public dialogService: DialogService,
    public router: Router,
    public dialogRef: MatDialogRef<MobileMenuComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { items: NavigationItem[]; services: any },
  ) {}

  openBooking() {
    this.dialogService.openBookings();
    this.dialogRef.close();
  }

  openLink(item: NavigationItem) {
    if (item.dropDownKey) {
      this.showServices.set(true);
    } else {
      this.router.navigate([item.path]);
      this.dialogRef.close();
    }
  }

  openServices(key: string, index: number) {
    this.router.navigate(['services'], { replaceUrl: false, queryParams: { type: key, index: index } });
    this.dialogRef.close();
  }
}

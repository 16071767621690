import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  WritableSignal,
  signal,
} from '@angular/core';
import { Router } from '@angular/router';
import KeenSlider, { KeenSliderInstance } from 'keen-slider';
import { Subscription, interval } from 'rxjs';
import { CloudinaryService } from '../../../../core/services/cloudinary.service';
import { DialogService } from '../../../../core/services/dialog.service';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import { CountUpDirective } from '../../../../shared/components/directives/count-up/count-up.directive';

@Component({
  selector: 'home-landing-widget',
  standalone: true,
  imports: [ButtonComponent, CountUpDirective, CommonModule],
  templateUrl: './home-landing-widget.component.html',
  styleUrl: './home-landing-widget.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeLandingWidgetComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('sliderRef') sliderRef: ElementRef<HTMLElement>;
  slider: KeenSliderInstance | null = null;
  interval = interval(7000);
  intervalSubscription: Subscription;
  activeIndex = signal(0);
  intersectData: WritableSignal<any> = signal({});

  data = [
    {
      title: 'Get 10% off any procedure as a new client!',
      info: "Schedule your session today and be a part of Arizona's exclusive glow revolution.",
      path: '',
      showLearMore: false,
      showReservation: true,
      image: 'skinLogic/LWXoTtqamUrK_fyeopv.png',
    },
    {
      title: 'CYNOGLOW',
      info: 'Uncover glowing, tighter, flawless skin like never before with Cynoglow, revolutionizing skincare with cutting-edge technology for unparalleled results, now available in Arizona',
      path: 'cynoglow',
      showLearMore: true,
      showReservation: true,
      image: 'misc/cyno_nskdat.png',
    },
    {
      title: 'Potenza™',
      info: "Discover Potenza: The world's first 4-mode RF microneedling device, offering customizable treatments with monopolar, bipolar, 1 and 2 MHz frequencies for unparalleled versatility and results. Schedule your free consultation now!",
      path: 'potenza',
      showLearMore: true,
      showReservation: true,
      image: 'skinLogic/misc/userlmn_4083af49608e01b7ee8a0086baabe84d_uctvgr.png',
    },
    {
      title: 'Treat Now Pay Later with Cherry',
      info: 'Let your money go further and take better control of your cash flow when you pay in smaller, more manageable monthly installments with Cherry',
      path: 'assets/cherry.html',
      showLearMore: true,
      showReservation: false,
      isExternal: true,
      image: 'misc/iwnkd2.png',
    },
  ];

  constructor(
    public cloudinary: CloudinaryService,
    private router: Router,
    public _dialogService: DialogService,
  ) {}
  ngOnInit(): void {
    this.intervalSubscription = this.interval.subscribe((_) => this.slider?.next());
  }

  opneBookings() {
    this._dialogService.openBookings();
  }

  ngAfterViewInit() {
    this.slider = new KeenSlider(this.sliderRef.nativeElement, {
      renderMode: 'performance',
      loop: true,
      drag: true,
      slideChanged: (s) => {
        this.activeIndex.set(s.track.details.rel);
        this.onIntersect(s.track.details.rel);
        if (this.intervalSubscription) {
          this.intervalSubscription.unsubscribe();
        }
        this.intervalSubscription = this.interval.subscribe((_) => this.slider?.next());
      },
      slides: {
        perView: 1,
        spacing: 0,
      },
    });
    // animation trigger
    const data: any = {};
    for (let index = 0; index < this.data.length; index++) {
      data[index] = index < 1;
    }
    this.intersectData.set(data);
  }

  onIntersect(index: number) {
    const value = this.intersectData();
    value[index] = true;
    this.intersectData.set(value);
  }

  openLink(item: any) {
    if (item.isExternal) {
      window.open(item.path, '_blank');
    } else {
      this.router.navigate([item.path], { replaceUrl: false });
    }
  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy();
    this.intervalSubscription?.unsubscribe();
  }
}
